<!--
 * @Description: 头部注释
 * @Author: v_binqin
 * @Date: 2022-11-27 16:19:30
 * @LastEditors: v_binqin
 * @LastEditTime: 2022-11-30 23:36:03
-->

<template>
  <div class="microbreak">
    <van-steps :active="active">
      <van-step>创建设备</van-step>
      <van-step>上传图片</van-step>
    </van-steps>
    <div class="upload">
      <div>
        <van-uploader
          max-count="1"
          v-model="fileList"
          :before-delete="deleteImg"
          multiple
        />
      </div>
      <van-button class="bottom-btn" round block type="info" @click="sendData">
        提交
      </van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  components: {},
  data() {
    return {
      active: 1,
      fileList: [],
      type: 0,
      id: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.type = this.$route.query.type;
      this.id = this.$route.query.qrresult;
    }, 1000);
  },
  methods: {
    deleteImg() {
      return true;
    },
    sendData() {
      if(this.fileList.length <1){
        Toast.fail('请上传图片')
        return
      }

      // this.$router.push('/')
      console.log(this.fileList[0].file);
      console.log("type:" + this.type);
      let params = {};
      if (this.type == 5) {
        if(this.id.indexOf(',')!=-1){
            this.id = this.id.split(',')[1]
        }
        params = {
          deviceId: this.id,
          gasDeviceType: this.type,
          microfractureDeviceDtos: [
            {
              addr: 1,
              hardwareType: 52,
              addressId: "",
              companyId: "",
              deviceId: "",
            },
          ],
        };
      } else {
        params = {
          deviceId: this.id,
          gasDeviceType: this.type,
        };
      }
      this.http.post("/mp/sg/device/add", params).then((res) => {
        if (res.data.code == 200) {
          let formData = new FormData();
          formData.append("files", this.fileList[0].file);
          formData.append("id", "40cf8952-4f08-4d8e-963c-a4ea86cf1b93");
          this.http
            .post("/mp/sg/device/uploadImage", formData, {
              headers: {
                // 表示上传的是文件,而不是普通的表单数据
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.data.code == 200) {
                Toast.success('添加成功');
                this.$router.push("/");
              }
            });

          // let form = new FormData();
          // form.append("id", res.data.data);
          // this.fileList = Array.from(this.fileList);
          // this.fileList.forEach((item) => {
          //   form.append("file", item.file);
          //   console.log(item.file)
          // });
          // this.http
          //   .post("/mp/sg/device/uploadImage", form, {
          //     headers: {
          //       // 表示上传的是文件,而不是普通的表单数据
          //       "Content-Type": "multipart/form-data",
          //     },
          //   })
          //   .then((res) => {
          //     if (res.data.code == 200) {
          //       this.$router.push("/");
          //     }
          //   });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.microbreak {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.van-steps {
  padding: 10px 20% 0;
}
.van-button {
  border: none;
}
.upload {
  padding: 0 20px;
}
.upload,
.van-form {
  position: relative;
  flex: 1;
  .bottom-btn {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }
}
</style>
